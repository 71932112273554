import Vue from 'vue'
import Meta from 'vue-meta'

import App from '@/App.vue'
import router from '@/plugins/router'
import store from '@/plugins/store'
import axios from '@/plugins/axios'

import AsyncImg from '@/templates/AsyncImg.vue'
import AppDefault from '@/templates/AppDefault.vue'

import '@/styles/init.scss'
import 'img-comparison-slider';

Vue.use(Meta, {
  refreshOnceOnNavigation: true
})

Vue.component('async-img', AsyncImg)
Vue.component('app-default', AppDefault)

Vue.config.productionTip = false
Vue.config.ignoredElements = [/img-comparison-slider/];

export default new Vue({
  router, store, axios,
  render: h => h(App),
}).$mount('#app')

