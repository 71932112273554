<template>
<app-default class="not-found">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 570px;">
      <h1 class="title is-uppercase is-1 app-header-company-title">
        404 Content Not Found
      </h1>
    </div>
    <div>
      <h3 class="subtitle is-2">
        .. Sorry no Content found, please contact us for more info ..
      </h3>
    </div>
  </template>

  <div class="my-6 container margin_mobile" style="max-width: 800px">

    <div class="my-4 py-4 has-text-centered">
      <h1 class="title is-uppercase is-1 app-header-company-title has-text-primary" style="font-size: 180px">
        <i class="fa fa-exclamation-triangle has-text-primary" style="font-size: 150px"></i>
        404
      </h1>
    </div>
    <div>
      <h3 class="subtitle is-2 has-text-centered mb-6 pb-6">
        Please go back to <br/>
        <router-link :to="{ name: 'Home'}" class="button is-primary is-outlined is-large has-text-centered mb-6">
          Home Page
        </router-link>
      </h3>
    </div>

      
  </div>

</app-default>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo: {
    title: '404 Not Found',
    meta: [
      { name: 'robots', content: 'noindex' },
    ]
  }
}
</script>