<template>
<div id="app">

  <app-header>
    <slot name="header"></slot>
  </app-header>

  <main class="app-main">
    <slot></slot>
  </main>

  <app-footer></app-footer>

</div>
</template>

<script>
import AppHeader from '@/parts/AppHeader.vue'
import AppFooter from '@/parts/AppFooter.vue'

export default {
  name: 'AppDefault',
  components: {
    AppHeader, AppFooter
  }
}
</script>
