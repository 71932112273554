<template>
<app-default class="ion-capability">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 620px;">
      <h1 class="title is-1 app-header-company-title">
        CALIBRATION CAPABILITIES
      </h1>
    </div>
    <div>
      <h3 class="subtitle is-5">
        STARTING FROM <b class="is-size-3">CALIBRATION,TRAINING,CONSULTATION</b> TO <b class="is-size-4">INSTRUMENT SOURCING</b>
        <b class="is-size-4"></b><b class="is-size-4"></b>
      </h3>
    </div>
  </template>

  <div class="my-6 container margin_mobile">
      
      <div class="has-text-centered mx-auto" style="max-width: 800px;">
        <p class="mb-3 head">
         To learn more about how you too can acquire precision from our Calibration Laboratory that includes adjustments, data and recall notification, contact us today. We can calibrate the instruments listed below.
        </p>
        <span>
          <a href="tel:+88 01810 036440-50" class="button is-outlined is-primary is-medium mr-1">+88 01810 036440</a>
          <a href="mailto:info@ionbd.com" class="button is-primary is-medium ml-1">info@ionbd.com</a>
        </span>
      </div>

      <div class="mt-6" v-for="(section, key) in products" :key="key">

        <div class="field is-grouped is-grouped-multiline is-pulled-right">
          <div class="control" v-for="(product, key2) in 3" :key="key2">
            <div class="tags has-addons">
              <a class="tag is-primary is-large px-4"></a>
              <a class="tag fas fa-info-circle is-large px-2"></a>
            </div>
          </div>
        </div>

        <h2 class="subtitle is-4 box has-background-primary has-text-white py-2 mb-2" style="display: inline-block;">
          {{section.section}}
        </h2>

        <table class="table is-bordered" width="100%">
          <tr v-for="(productColumns, key2) in section.products" :key="key2">
            <td style="width: 33.333333%" v-for="(product, key3) in productColumns" :key="key3"> {{ product }} </td>
          </tr>
        </table>

      </div>

  </div>

</app-default>
</template>

<script>
export default {
  name: 'CalibrationCapabilities',
  metaInfo: {
    title: 'Best calibration service capabilities - ION BD',
    meta: [
      { name: 'og:title', content: 'CALIBRATION CAPABILITY' },
      { name: 'keywords', content: 'Pressure Calibration, Calibration of Manometer,Calibration of Magnahilic gauge gauge,Pressure gauges,Safety valve calibration ,Vacuum gauges calibraton,Force-Mass Calibration,Load cell calibration,calibration of pipette,calibration of Weighing balances,Torque wrench,Dimension Calibration,Calibration of Dial indicators,Electro Technical Calibration,Calibration of Multimeter,Resistance decade box,Thermo-Hygrometer,Caliper,Laminar air Flow,Sound Level Measurement,Thermal and Humidity calibration,Time calibraiton,Analytical instruments calibration,Calibration of Tachometer,Calibration of Lux Meter,xnx gas detector calibration,xnx gas detector calibration 9,xnx gas detector calibration machine price,xnx honeywell gas detector calibration xnx, test equipment calibration,equipment calibration,hipot test calibration,torque wrench calibration,ph meter calibrationhplc calibration,megger meter,compression testing machine,multifunction tester' },
      
      { name: 'description', content: 'To learn more about how you too can acquire precision from our Calibration Laboratory that includes adjustments, data and recall notification, contact us today. We can calibrate the instruments listed below' },

      { name: 'og:description', content: 'To learn more about how you too can acquire precision from our Calibration Laboratory that includes adjustments, data and recall notification, contact us today. We can calibrate the instruments listed below.' },
    ]
  },
  data: function () {
    return {
      search: null,
      products: []
    }
  },
  methods: {
    getProducts() {
      let vm = this;
      vm.$http.get(vm.$store.state.site_url + '/ion-capability.json').then((response) => {
        vm.products = response.data.payload
      }).catch(() => {})
    }
  },
  created() {
    this.getProducts()
  }
}
</script>