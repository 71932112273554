import Vue from 'vue'
// import vm from '@/main'
import Axios from 'axios'

Axios.defaults.headers.common['Accept'] = 'application/json'

// Add a request interceptor
// Axios.interceptors.request.use(function (config) {
//   if(vm.$store.state.global.token)
//     // config.headers.Authorization = 'Bearer ' + vm.$store.state.global.token
//   vm.$store.commit('stateChange', { state:'__is_loading_open', data:true})
//   return config
// }, function (error) {
//   return Promise.reject(error)
// });

// Axios.interceptors.response.use(function (response) {
//   vm.$store.commit('stateChange', { state:'__is_loading_open', data:false})
//   return response;
// }, function (error) {
//   vm.$store.commit('stateChange', { state:'__is_loading_open', data:false})
  
//   if(error.response.status == 422) {
//     // vm.$buefy.toast.open({
//     //   message: error.response.data.message,
//     //   type: 'is-danger', queue: false, 
//     //   position: 'is-bottom-right'
//     // })
//     if(error.response.data.errors) {
//       // let errorMsg = '';
//       // for (var key in error.response.data.errors) {
//       //   errorMsg += error.response.data.errors[key] + '<br>';
//       // }
//       // vm.$buefy.toast.open({
//       //   message: errorMsg,
//       //   type: 'is-primary', queue: false, 
//       //   position: 'is-bottom-right'
//       // })
//     }
//   }

//   else if(error.response.status == 401) {
    
//     if(vm.$store.state.global.token != null)
//       vm.$store.commit('removeApiCookie')
    
//     if(vm.$store.state.protected.includes(vm.$route.name))
//       vm.$router.push({ name: 'Login' })
//   }

//   else {
//     // vm.$buefy.toast.open({
//     //   message: error.response.data.message,
//     //   type: 'is-danger', queue: false, 
//     //   position: 'is-bottom-right'
//     // })
//   }

//   return Promise.reject(error);
// });

Vue.prototype.$http = Axios

export default Axios
