<template>
<app-default class="equipment-sourcing">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 505px;">
      <h1 class="title is-1 app-header-company-title">
        EQUIPMENT SOURCING
      </h1>
    </div>
    <div>
      <h3 class="subtitle is-5">
        STARTING FROM <b class="is-size-3">CALIBRATION, CONSULTATION</b> TO <b class="is-size-4">INSTRUMENT SOURCING</b>
       <!--  <b class="is-size-4">YOUR URGENCY</b> IS <b class="is-size-4">OUR PRIORITY</b> -->
      </h3>
      <div class="field mx-auto" style="max-width: 600px">
        <div class="control has-icons-right">
          <input class="input is-primary is-medium" type="text" placeholder="Search equipments ..." v-model="search">
          <span class="icon is-small is-right">
            <i class="fas fa-search"></i>
          </span>
        </div>
      </div>
    </div>
  </template>

  <div class="my-6 container margin_mobile">
      <div class="">
        <h2 class="subtitle is-2 has-text-right">
          We can source almost any kind of equipment
          <button class="button is-primary is-outlined">EQUIPMENT SOURCE REQUEST</button>
        </h2>
      </div>
      <div class="is-2 my-5" v-for="(section, key) in products" :key="key">
        <h2 class="subtitle is-4 box has-background-primary has-text-white py-2 mb-0" style="display: inline-block;"> {{section.section}} </h2>
        <div class="columns has-text-centered is-multiline">
          <div class="column is-2 my-5" v-for="(product, key2) in section.products" :key="key2">
            <router-link :to="{ name: 'EquipmentDetail', params: { slug: product.slug } }" class="box">
              <async-img
                img_style="height:150px;width:150px;"
                :src="$store.state.site_url + '/products/'+ product.slug +'/thumbnail.150.webp'" :img_alt="product.name"/>
            </router-link>
            <h3 class="subtitle is-4">{{ product.name }}</h3>
          </div>
        </div>
      </div>
  </div>

</app-default>
</template>

<script>

export default {
  name: 'EquipmentSourcing',
  metaInfo: {
    title: 'Best Instrument Calibrator & Equipment Supplier In Bangladesh',
    meta: [
      { name: 'og:title', content: 'ION equipment supplier' },
      { name: 'keywords', content: 'fluke, dry block temperature calibrator, very high temperature, dry block calibrator, oil bath, liquid bath, liquid bath calibrator, digital pressure calibrators, differential pressure manometer, pressure calibrator, hydraulic calibration pump, hydraulic pressure calibrator, vacuum hand pump, digital pressure gauge ,multifunction process calibrator, multi-function calibrator, fluke 754 process calibrator, multimeter, current coil, decade resistance box, multi-product calibrator, gauge block set, slip gauge set, micrometer, caliper, caliper checker, dial indicator tester, scale & tape calibration systems, tape & scale calibrator, weight box, analytical balance, weights, 5 digit semi micro analytical balance, laboratory balance' },
      
      { name: 'description', content: 'ION’s Calibrator and lab equipment’s the ideal product for your calibration lab. We offer affordable Calibration and laboratory equipment’s in Bangladesh.' },

      { name: 'og:description', content: 'ION’s Calibrator and lab equipment’s the ideal product for your calibration lab. We offer affordable Calibration and laboratory equipment’s in Bangladesh.' },
    ]
  },
  data: function () {
    return {
      search: null,
      products: []
    }
  },
  methods: {
    getProducts() {
      let vm = this;
      vm.$http.get(vm.$store.state.site_url + '/products/index.json').then((response) => {
        vm.products = response.data.payload
      }).catch(() => {})
    }
  },
  created() {
    this.getProducts()
  }
}
</script>