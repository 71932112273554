import Vue from 'vue'
import Router from 'vue-router'

// Pages

import Home from '@/pages/Home.vue'
import CalibrationService from '@/pages/CalibrationService.vue'
import EquipmentSourcing from '@/pages/EquipmentSourcing.vue'
import EquipmentDetail from '@/pages/EquipmentDetail.vue'
import LabConsultation from '@/pages/LabConsultation.vue'
import WhyIon from '@/pages/WhyIon.vue'
import IonCareer from '@/pages/IonCareer.vue'
import MissionVission from '@/pages/MissionVission.vue'
import AboutUs from '@/pages/AboutUs.vue'
import Contact from '@/pages/Contact.vue'
import CalibrationCapabilities from '@/pages/CalibrationCapabilities.vue'
import VerifyCalibration from '@/pages/VerifyCalibration.vue'
import NotFound from '@/pages/NotFound.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [

    // Pages
    { path: '/', name: 'Home', component: Home },
    { path: '/calibration-service', name: 'CalibrationService', component: CalibrationService },
    { path: '/calibration-capabilities', name: 'CalibrationCapabilities', component: CalibrationCapabilities },
    { path: '/equipment-sourcing', name: 'EquipmentSourcing', component: EquipmentSourcing },
    { path: '/equipment/:slug', name: 'EquipmentDetail', component: EquipmentDetail },
    { path: '/lab-consultation', name: 'LabConsultation', component: LabConsultation },
    { path: '/why-ion-engineering-pvt-limited', name: 'WhyIon', component: WhyIon },
    { path: '/career-in-ion-engineering-pvt-limited', name: 'IonCareer', component: IonCareer },
    { path: '/mission-vission', name: 'MissionVission', component: MissionVission },
    { path: '/certificate-verification', name: 'VerifyCalibration', component: VerifyCalibration },
    { path: '/about-us', name: 'AboutUs', component: AboutUs },
    { path: '/contact', name: 'Contact', component: Contact },
    
    // Error Page
    { path: '*', name: 'NotFound', component: NotFound }
  ]
})
