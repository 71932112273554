<template>
<app-default class="calibration-service">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 505px;">
      <h1 class="title is-1 app-header-company-title">
        CALIBRATION SERVICE
      </h1>
    </div>
    <div>
      <h3 class="subtitle is-5">
        
        <span class="is-size-5 is-uppercase">
          Cheacout  WHY ION ENGINEERING PVT. LIMITED  capability on consultation service
        </span>
        <br/>
        <br/>

        <router-link :to="{ name: 'CalibrationCapabilities'}" class="button is-large is-primary">
          ION ENGINEERING CAPABILITY
        </router-link>

      </h3>
    </div>
  </template>

  <br/><br/>

  <div class="container purpose-of-calibration">
    <div class="columns">
      <div class="column is-6">
        <h2 class="title is-3 has-text-white has-background-primary py-2">
          <span class="container px-5">PURPOSE OF CALIBRATION</span>
        </h2>
        <p class="is-size-3">
          There are three main reasons for having instruments calibrated:
          <ol class="ml-5">
            <li>To ensure readings from an instrument are consistent with other measurements.</li>
            <li>To determine the accuracy of the instrument readings.</li>
            <li>To establish the reliability of the instrument i.e. that it can be trusted.</li>
          </ol>
        </p>
      </div>
      <div class="column is-6">
        <h2 class="title is-3 has-text-white has-background-primary py-2">
          <span class="container px-5">ION’S APPROACH TO CALIBRATION</span>
        </h2>
        <p class="is-size-3">
          Our reference standards are traceable to the International System of Units (SI). In-house calibration services are performed in our environmentally controlled metrology laboratory, which allows us to offer lowest measurement uncertainty for your precise standards and instruments.
        </p>
      </div>
    </div>
  </div>

  <br/>

  <h2 class="subtitle is-3 has-text-left container"></h2>

  <br/>

  <div class="has-background-primary">
    <div class="container">
      <div class="columns">
        <div class="column is-8 has-text-white">
          <h2 class="title is-1 has-text-white has-background-primary py-2 mt-4">
            <span class="container px-5" style="display:block;">THE PERFECT RANGES FOR YOUR REQUIREMENTS</span>
          </h2>
          <p class="is-size-3 mx-5">
            Our state of the calibration lab features the high quality primary reference standards, allowing us to offer
            the ranges and capabilities our customers requirement.
            <br/>
            <br/>
            <table class="table" width="100%" style="box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2); border-radius: 10px;">
              <tr><th>Temperature </th><td>: -50ºC to 1500ºC</td></tr>
              <tr><th>Relative Humidity </th><td>: 10%RH to 95%RH</td></tr>
              <tr><th>Pressure </th><td>: -1bar to 1000 bar</td></tr>
              <tr><th>Mass </th><td>: 1 mg to 5 ton</td></tr>
              <tr><th>Volume </th><td>: 1 µl to 20 ltr</td></tr>
              <tr><th>Torque </th><td>: 1 N-m to 1000 N-m</td></tr>
              <tr><th>Sound </th><td>: 94dB, 104dB, 114dB@1kHz</td></tr>
              <tr><th>Gas Flow </th><td>: 0.5 SLPM to 4000 SLPM</td></tr>
              <tr><th>Gas Detector </th><td>: O2, CO, CO2, H2S, etc.</td></tr>
              <tr><th>Liquid Flow </th><td>: 0.5m³/hr to 240m³/hr</td></tr>
              <tr><th>Length </th><td>: 1µm to 1000mm</td></tr>
              <tr>
                <th style="border: 1px solid transparent;">Electro-Technical </th>
                <td style="border: 1px solid transparent;">
                  : DC Voltage, DC Current,AC Voltage,AC Current, DC Resistance, <br/> &nbsp;  AC Resistance, Capacitance,
                    &nbsp; Inductance, Frequency, DC Power, <br/>  &nbsp; AC Power, Power Factor,Active Energy,TransformerTurns Ratio, etc.
                </td>
              </tr>
              
            </table>
          </p>
        </div>
        <div class="column is-4">
          <async-img v-if="$store.state.window_width > 1024" :src="require(`@/assets/calibration-service/home-tape.webp`)" img_alt="Tape Mesure" img_style="margin-top: -20px;height:650px;width:350px"/>
        </div>
      </div>
    </div>

    <br/><br/>

  </div>

  <br/>

  <div class="container all-parameters">
    <div class="columns is-multiline">
      <div class="column is-6">
        <h2 class="subtitle is-3 has-text-white has-background-primary py-2">
          <span class="container px-5" style="display:block;">ALL PARAMETERS UNDER ONE ROOF</span>
        </h2>
        <p class="is-size-3">
          With a largest range of calibration standards in temperature/Thermal, Pressure/Vacuum, humidity, mass, electro-technical/Electrical, Dimension, Speed, Force, parameters and more, calibration lab can calibrate thousands of makes and models of standards and instruments.
        </p>
      </div>
      <div class="column is-6">

        <h2 class="subtitle is-3 has-text-white has-background-primary py-2">
          <span class="container px-5" style="display:block;">STRICT QUALITY REVIEW FOR ALL CALIBRATIONS</span>
        </h2>
        <p class="is-size-3">
          All calibration services meet ISO 17025:2015 & NABL requirements. Our highly experienced engineers review and verify each and every calibration documentation, which provides the critical information you need to support the accuracy
          requirements of your laboratory and industrial instruments.
        </p>
        
      </div>
      <div class="column is-12">

        <h2 class="subtitle is-3 has-text-white has-background-primary py-2">
          <span class="container px-5" style="display:block;">QUALITY REVIEW FOR ALL CALIBRATION SERVICES</span>

        </h2>
        <p class="is-size-3">
          All calibration services meet ISO 17025:2015 & NABL requirements. Our highly experienced engineers review and verify each and every calibration documentation, which provides the critical information you need to support the accuracy
          requirements of your laboratory and industrial instruments.
        </p>
      </div>
      <div class="column is-12">
        <h2 class="subtitle is-3 has-text-white has-background-primary py-2">
          <span class="container px-5" style="display:block;">QUICK TURNAROUND WHICH KEEPS YOUR OPERATIONS ON-SCHEDULE</span>
        </h2>
        <p class="is-size-3">
          We understand sending your instruments out for calibration can impact your activities. Our large suite of
          standards, qualified staff and automated workflow helps get your instruments back to you quickly with
          minimum disruption to your operation.
        </p>
      </div>
    </div>
  </div>

    <br/><br/>

  <div class="has-background-primary has-text-centered has-text-white">

    <div class="container pt-2">

      <h2 class="subtitle is-1 py-2 pt-6 mb-6 has-text- has-text-white">ION ENGNEEARING CALIBRATION SERVICES</h2>

      <div class="columns">
        <div class="column is-3">
          <async-img :src="require(`@/assets/calibration-service/home-sourcing1.webp`)" img_alt="In-Lab Calibration" img_style="height:140px;width:190px"/>
          <h2 class="subtitle is-1 has-text-white">In-Lab Calibration</h2>
        </div>
        <div class="column is-3">
          <async-img :src="require(`@/assets/calibration-service/home-sourcing2.webp`)" img_alt="Onsite Calibration" img_style="height:140px;width:190px"/>
          <h2 class="subtitle is-1 has-text-white">Onsite Calibration</h2>
        </div>
        <div class="column is-3">
          <async-img :src="require(`@/assets/calibration-service/home-sourcing3.webp`)" img_alt="In-Process Calibration" img_style="height:140px;width:190px"/>
          <h2 class="subtitle is-1 has-text-white">In-Process Calibration</h2>
        </div>
        <div class="column is-3">
          <async-img :src="require(`@/assets/calibration-service/home-sourcing4.webp`)" img_alt="Managed Services" img_style="height:140px;width:190px"/>
          <h2 class="subtitle is-1 has-text-white">Managed Services</h2>
        </div>
      </div>

    </div>

    <br/><br/>

  </div>

  <br/><br/>

  <div class="container some-of-our-clients">

    <div class="abbr has-text-left">
      <h3 class="title is-2">SOME OF OUR CLIENTS</h3>
    </div>

    <div class="columns mt-3 mb-6">
      <div class="column is-2">
        <div class="box">
          <async-img :src="require(`@/assets/clients/cli-01-min.webp`)" img_alt="SOME OF OUR CLIENTS" img_style="height:90px;width:180px"/>
        </div>
      </div>
      <div class="column is-2">
        <div class="box">
          <async-img :src="require(`@/assets/clients/cli-02-min.webp`)" img_alt="SOME OF OUR CLIENTS" img_style="height:90px;width:180px"/>
        </div>
      </div>
      <div class="column is-2">
        <div class="box">
          <async-img :src="require(`@/assets/clients/cli-03-min.webp`)" img_alt="SOME OF OUR CLIENTS" img_style="height:90px;width:180px"/>
        </div>
      </div>
      <div class="column is-2">
        <div class="box">
          <async-img :src="require(`@/assets/clients/cli-04-min.webp`)" img_alt="SOME OF OUR CLIENTS" img_style="height:90px;width:180px"/>
        </div>
      </div>
      <div class="column is-2">
        <div class="box">
          <async-img :src="require(`@/assets/clients/cli-05-min.webp`)" img_alt="SOME OF OUR CLIENTS" img_style="height:90px;width:180px"/>
        </div>
      </div>
      <div class="column is-2">
        <div class="box">
          <async-img :src="require(`@/assets/clients/cli-06-min.webp`)" img_alt="SOME OF OUR CLIENTS" img_style="height:90px;width:180px"/>
        </div>
      </div>
    </div>

  </div>



  <br/>
</app-default>
</template>

<script>
export default {
  name: 'CalibrationService',
  metaInfo: {
    title: 'Calibration service laboratory in bd',
    meta: [
      { name: 'og:title', content: 'ION CALIBRATION SERVICE' },
      { name: 'keywords', content: 'Calibration Service in Bangladesh, Calibration Service bd, Accredited lab, iso 17025, gas detector, bw gas detector,Calibration, temperature calibration, pressure calibration,gas detector calibration,weight calibration, volume calibration,balance calibration,textile instruments calibration,fluke,calibrator,DC Voltage, DC Current, AC Voltage, AC Current, DC Resistance, AC Resistance, capacitance, inductance, Frequency, DC Power, AC Power, flowmeter calibration,torque calibration,sound Calibration,volume calibration,xnx gas detector calibration,xnx gas detector calibration 9,xnx gas detector calibration machine price,xnx honeywell gas detector calibration xnx, test equipment calibration' },

      { name: 'description', content: 'All calibration services meet ISO 17025:2015 & NABL requirements. Our highly experienced engineers review and verify each and every calibration documentation, which provides the critical information you need to support the accuracy requirements of your laboratory and industrial instruments.' },

      { name: 'og:description', content: 'All calibration services meet ISO 17025:2015 & NABL requirements. Our highly experienced engineers review and verify each and every calibration documentation, which provides the critical information you need to support the accuracy requirements of your laboratory and industrial instruments.' },
    ]
  }
}
</script>