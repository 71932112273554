<template>
<app-default class="why-ion">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 510px;">
      <h1 class="title is-uppercase is-1 app-header-company-title">
        WHY ION ENGINEERING
      </h1>
    </div>
    <div>
      <h3 class="subtitle is-5">
        STARTING FROM <b class="is-size-3">CALIBRATION, TRAINING, INSTRUMENT SOURCING</b> TO <b class="is-size-4">CONSULTATION</b>
        <b class="is-size-4"></b> Service<b class="is-size-4"></b>
      </h3>
    </div>
  </template>

  <div class="my-6 container margin_mobile" style="max-width: 800px">
    
    <h2 class="title is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">Why ION Calibration service?</h2>
    <p>ION Engineering (Pvt.) Ltd is one of the country’s leading calibration service company. We believe in quality service and customers satisfaction. We understand that customers don’t just need a calibration certificate from the ISO17025 laboratory; they need support to validate, verify, and ensure that their Testing and Measuring Equipment give credible results. We take responsibility in helping and supporting our customers to effectively use the results from their calibration or testing report to make decisions that will help their teams and companies to improve.With more than 07+ years of experience in the calibration industry, we are privileged to be servicing your products with utmost integrity, reliability, professional and traceable calibration to improve your business.</p>

    <br/>

    <div class="columns has-text-centered">
      <div class="column is-4">
        <async-img
          img_style="height:180px;width:200px;"
          :src="require(`@/assets/why-ion/calibration-service-1-min.webp`)" img_alt="Lab Consultation"/>
      </div>
      <div class="column is-4">
        <async-img
          v-if="$store.state.window_width > 1024"
          img_style="height:180px;width:200px;"
          :src="require(`@/assets/why-ion/calibration-service-2-min.webp`)" img_alt="Lab Consultation"/>
      </div>
      <div class="column is-4">
        <async-img
          img_style="height:180px;width:200px;"
          :src="require(`@/assets/why-ion/calibration-service-3-min.webp`)" img_alt="Lab Consultation"/>
      </div>
    </div>

    <br/>

    <h2 class="title is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">Why ION Equipment?</h2>
    <p>ION Engineering (Pvt.) Ltd is one of the largest solution providers for Laboratory and Industrial measuring products. ION has a strong Sales & Service network, operating at various important locations in Bangladesh and a global network. ION is continuously expanding its global presence in order to achieve its vision of becoming one of the leading engineering solution providers globally. ION is continuing its constant endeavor of delivering solutions for metrology to its large base of over one thousand five hundred satisfied customers worldwide.</p>

    <br/>

    <h2 class="title is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">Why ION Consultancy?</h2>
    
    <br/> <br/>
    
    <async-img
      img_class="is-pulled-left mr-3"
      img_style="height:100px;width:150px;"
      :src="require(`@/assets/why-ion/calibration-service-4-min.webp`)" img_alt="Lab Consultation"/>

    <p>Over the years, we have led the way in ISO management system certification implementation services and making them relevant to your test laboratory / calibration laboratory resulting in tangible business benefits for your laboratory. No other ISO 17025 accreditation consulting company can offer the expertise, knowledge, professional consulting approach, timely project completion and recognition that comes with working with the ISO 17025 laboratory accreditation consulting experts of ION Engineering (Pvt.) Ltd.</p>

    <br/> <br/>

    <h2 class="title is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">Why ION Training?</h2>
    <p> We organize training programmes & workshops on calibration at regular intervals to enhance and update knowledge of customers. Faculty for training is arranged from prestigious and renowned institutions and industries. Also, workshop and calibration lab tour in our calibration laboratory on open day is arranged in laboratory. We also organize the training programs on ISO 17025 awareness, ISO 17025 auditor, documentation and measurement uncertainty, calibration of various instruments, API etc. Customized training programmes are also arranged at customer's site.</p>

    <br/> <br/>

    <h2 class="title is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">The People at ION Calibration Laboratory</h2>
    <p> We are currently performing calibration in the field of temperature, dimensional, pressure, vacuum, time, mass, electrical, noise, airflow, lux & all special purpose instruments in all ranges. ION is ISO/IEC 17025:2017 accredited calibration laboratory by NABL We had highly skilled and experienced engineers and staff of over twenty professionals. With shift working, we are able to satisfy your emergency situations, as well as, provide quick turnaround calibration services on a consistent basis and carry out calibration for process equipment’s and test standards with our calibrated instruments and standards.</p>
      
  </div>

</app-default>
</template>

<script>
export default {
  name: 'LabConsultation',
  metaInfo: {
    title: 'Why ION Calibration service',
    meta: [
      { name: 'og:title', content: 'Why ION Calibration service' },
      { name: 'keywords', content: 'Calibration, temperature calibration, pressure calibration,gas detector calibration,weight calibration, volume calibration,balance calibration,textile instruments calibration,calibrator,emperature, Dimensional, Pressure, Vacuum, Time, Mass, Electrical, Noise, Airflow, Lux, calibration certificate,traceable calibration' },
      
      { name: 'description', content: 'We are currently performing calibration in the field of Temperature, Dimensional, Pressure, Vacuum, Time, Mass, Electrical, Noise, Airflow, Lux & all Special Purpose Instruments in all ranges. ION is ISO/IEC 17025:2017 accredited calibration laboratory by NABL We had highly skilled and experienced engineers and staff of over twenty professionals.' },

      { name: 'og:description', content: 'We are currently performing calibration in the field of Temperature, Dimensional, Pressure, Vacuum, Time, Mass, Electrical, Noise, Airflow, Lux & all Special Purpose Instruments in all ranges. ION is ISO/IEC 17025:2017 accredited calibration laboratory by NABL We had highly skilled and experienced engineers and staff of over twenty professionals.' },
    ]
  }
}
</script>