<template>
<app-default class="contact-us">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 285px;">
      <h1 class="title is-uppercase is-1 app-header-company-title">
        Contact Us
      </h1>
      <h3 class="subtitle is-5"> &nbsp; <br/> <br/> </h3>
    </div>

  </template>

  <div class="my-6 container margin_mobile" style="max-width:900px;">
      <div class="mx-auto has-text-left" >
        <div class="columns is-multiline">


          <div class="column is-12 has-text-right">
            <h1 class="title is-1">
              ION Engineering (Myanmar) Co; Ltd.
              <span class="is-size-5 py-2 has-text-grey is-pulled-left">Branch Office</span>
            </h1>
          </div>

          <div class="column is-6">
            
            <div class="box px-1 py-4">                
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26858.144756341688!2d96.09815778349045!3d16.910919483506827!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c195aae5867235%3A0x523073252e867fc6!2sION%20Engineering%20%7C%20best%20calibration%20lab%20%26%20equipment%20supplier%20in%20Myanmar!5e0!3m2!1sen!2sbd!4v1657615839966!5m2!1sen!2sbd" width="100%" height="280" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

          </div>

          <div class="column is-6">
            
            <div class="px-2 py-6 mb-2">
              <div class="">
                <span class="icon is-large has-text-grey">
                  <i class="fa fa-fas fa-headset fa-3x"></i>
                </span> <br>
                <span class="is-size-5 has-text-grey"> Any Question? Call us !</span> <br/>
                <span class="is-size-1">+95 9 44376 2421</span> <br/>
                <span class="is-size-1">+88 01713 588601 (Whatsapp)
                </span> <br/>
              </div>
              <div class="mx-2 mt-4 mb-4">
                <span class="is-size-3">win.thu@ionbd.com</span> <br/> <span class="is-size-3">info@ionbd.com</span> <br/>
              </div>
              <address class="mx-2">
                <span class="is-size-4">No.96/B, Thiri Mingalar (8) Street, East Yward, Insein Tsp<br/> Yangon-11012, Myanmar (Burma) </span>
              </address>

            </div>




          </div>

        </div>

        <div class="container has-text-centered">
          <h3 class="title">We Would Love to Talk </h3>
          <p class="my-3 head">
            We love conversations, and would love to have one with you! Whether you’re looking for a heigh speed connection or want to get started with a rewards program, we would love hear from you. 
          </p>
          <span class="">
            <a href="tel:+95 9 44376 2421" class="button is-outlined is-primary is-large mr-1">+95 9 44376 2421</a>
            <a href="mailto:info@ioncl.com" class="button is-primary is-large ml-1">info@ionbd.com</a>
          </span>
        </div>

      </div>

  </div>

  

  

  

</app-default>
</template>

<script>
export default {
  name: 'ContactPage',
  metaInfo: {
    title: 'ION Calibration Service & Equipment Supplier Contact',
    meta: [
      { name: 'og:title', content: 'Contact' },
      { name: 'keywords', content: 'ion Contact, calibration Contact, calibrator supplier ' },

      { name: 'description', content: 'We love conversations, and would love to have one with you! Whether you’re looking for a heigh speed connection or want to get started with a rewards program, we would love hear from you. ' },

      { name: 'og:description', content: 'We love conversations, and would love to have one with you! Whether you’re looking for a heigh speed connection or want to get started with a rewards program, we would love hear from you. ' },
    ]
  }
}
</script>