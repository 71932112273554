<template>
<nav class="navbar is-dark" role="navigation" aria-label="main navigation">
  <div class="container">
    <div class="navbar-brand">
      <router-link :to="{ name: 'Home'}" class="navbar-item">
        <img class="logo" height="20px" width="30px" src="@/assets/default/logo-white-min.png" alt="Logo">
      </router-link>
      <a href="#" class="navbar-burger" @click.prevent="is_navbar_expanded = !is_navbar_expanded">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div :class="is_navbar_expanded?'navbar-menu is-active':'navbar-menu'">
      <div class="navbar-start">

        <router-link :to="{ name: 'Home'}" class="navbar-item">
          Home
        </router-link>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            Calibration
          </a>

          <div class="navbar-dropdown">

            <router-link :to="{ name: 'CalibrationService'}" class="navbar-item">
              Calibration Service
            </router-link>

            <router-link :to="{ name: 'CalibrationCapabilities'}" class="navbar-item">
              Calibration Capabilities
            </router-link>

          </div>
        </div>

        <router-link :to="{ name: 'EquipmentSourcing'}" class="navbar-item">
          Equipment Sourcing
        </router-link>

        <router-link :to="{ name: 'LabConsultation'}" class="navbar-item">
          Lab Consultation
        </router-link>

      </div>

      <div class="navbar-end">

        <router-link :to="{ name: 'WhyIon'}" class="navbar-item">
          WHY ION ENGINEERING PVT. LIMITED
        </router-link>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            More
          </a>

          <div class="navbar-dropdown is-right">
            <router-link :to="{ name: 'AboutUs'}" class="navbar-item">
              About Us
            </router-link>
            <router-link :to="{ name: 'CalibrationCapabilities'}" class="navbar-item">
              Our Capability
            </router-link>
            <router-link :to="{ name: 'MissionVission'}" class="navbar-item">
              Mission & Vission
            </router-link>
            <router-link :to="{ name: 'IonCareer'}" class="navbar-item">
              Career
            </router-link>
            <hr class="navbar-divider">
            <router-link :to="{ name: 'Contact'}" class="navbar-item">
              Contact
            </router-link>
          </div>
        </div>

      </div>
    </div>

  </div>
</nav>
</template>
<script>
export default {
  name: 'AppNavbar',
  data: function () {
    return {
      is_navbar_expanded: false
    }
  }
}
</script>