<template>
<app-default class="home">

  <template #header>
    <div class="typewriter" style="width: 735px;">
      
      <img class="has-background-primary logo" src="@/assets/home/logo-min.png" alt="Logo" style="margin: -1px">
      
      <h1 class="title is-10 app-header-company-title">
        ION ENGINEERING (MM) CO; LTD.
      </h1>

      <h3 class="subtitle is-5 app-header-company-subtitle">
        STARTING FROM <b class="is-size-3">LABORATORY EQUIPMENT, CALIBRATION</b> TO <b class="is-size-3">LAB CONSULTANCY</b>
        <br/> <b class="is-size-4">(ISO/IEC 17025:2017 Accredited Lab</b> & <b class="is-size-4">ISO 9001:2015 Certified Organization)</b>
      </h3>

    </div>
  </template>

  <div class="container has-text-centered app-service-steak">
    <div class="columns ">
      <router-link :to="{ name: 'EquipmentSourcing'}" class="column">
        <span class="box mx-4 is-paddingless">
          <async-img :src="require(`@/assets/home/home-sourcing-pic-min.webp`)" img_alt="EQUIPMENT SOURCING"/>
        </span>
        <span class="title px-0 py-0">EQUIPMENT SOURCING</span>
      </router-link>
      <router-link :to="{ name: 'CalibrationCapabilities'}" class="column">
        <span class="box mx-4 is-paddingless">
          <async-img :src="require(`@/assets/home/home-calibration-pic-min.webp`)" img_alt="CALIBRATION SERVICE"/>
        </span>
        <span class="title px-0 py-0">CALIBRATION SERVICE</span>
      </router-link>
      <router-link :to="{ name: 'LabConsultation'}" class="column">
        <span class="box mx-4 is-paddingless">
          <async-img :src="require(`@/assets/home/home-labratory-pic-min.webp`)" img_alt="LAB CONSULTANCY"/>
        </span>
        <span class="title px-0 py-0">LAB CONSULTANCY</span>
      </router-link>
    </div>
  </div>

  <div class="container app-calibration-steak">
    <div class="abbr mb-6">
      <div class="is-pulled-left brand-controller" style="display:none">
        <img class="mr-4" height="80px" width="80px" src="@/assets/home/calibration-nabl-min.jpg" alt="NABL Calibration">
        <img class="mr-4" height="80px" width="60px" src="@/assets/home/global-acceptance-min.jpg" alt="Global Acceptance">
      </div>
      <h2 class="title is-2"> BEST ACCREDITED CALIBRATION COMPANY IN MYANMAR</h2>
      <p class="subtitle is-3 pl-5">
        Myanmar’s first software tracking base ISO/IEC 17025:2017 accredited & ISO 9001:2015 certified calibration laboratory and leading calibration company in Myanmar. ION Engineering (Pvt.) Ltd is a fully equipped calibration laboratory as well as industrial equipment, laboratory equipment & measuring devices supplier. ION Engineering (Pvt.) Ltd provides accuracy testing, calibration, training, consultation and measurement solutions from local and international organizations.
      </p>
    </div>
  </div>

  <div class="container some-of-our-partners">

    <div class="abbr has-text-right">
      <h5 class="title is-2">SOME OF OUR PARTNERS</h5>
    </div>

    <div class="columns mt-3 mb-6">
      <div class="column is-2">
        <div class="box">
          <img alt="PARTNERS LOGO" src="@/assets/clients/cli-01-min.webp" height="100px" width="185px">
        </div>
      </div>
      <div class="column is-2">
        <div class="box">
          <img alt="PARTNERS LOGO" src="@/assets/clients/cli-02-min.webp" height="100px" width="185px">
        </div>
      </div>
      <div class="column is-2">
        <div class="box">
          <img alt="PARTNERS LOGO" src="@/assets/clients/cli-03-min.webp" height="100px" width="185px">
        </div>
      </div>
      <div class="column is-2">
        <div class="box">
          <img alt="PARTNERS LOGO" src="@/assets/clients/cli-04-min.webp" height="100px" width="185px">
        </div>
      </div>
      <div class="column is-2">
        <div class="box">
          <img alt="PARTNERS LOGO" src="@/assets/clients/cli-05-min.webp" height="100px" width="185px">
        </div>
      </div>
      <div class="column is-2">
        <div class="box">
          <img alt="PARTNERS LOGO" src="@/assets/clients/cli-06-min.webp" height="100px" width="185px">
        </div>
      </div>
    </div>

  </div>

    

  <div class="calibration-pannel">
    <div class="container py-6">
      <div class="columns">
        <div class="column">
          
          <div class="pannel-box pb-6">
            <div class="circle is-size-1 has-text-weight-bold">01</div>
            <h3 class="title is-3 pl-6 ml-6 mb-4">CALIBRATION SERVICE</h3>
            <p class="is-size-4 pl-6 ml-6">ION Engineering (Pvt.) Ltd is one of the best calibration companies in Bangladesh that features the highest quality primary reference standards, allowing us to offer the ranges and capabilities according to our customer’s demand to ensure quality calibration service.We offer calibration services for a variety of instruments including power plants, pharmaceuticals, textiles, food and beverages, and laboratory equipments.</p>
          </div>

          <div class="pannel-box pb-6">
            <div class="circle is-size-1 has-text-weight-bold">02</div>
            <h3 class="title is-3 pl-6 ml-6 mb-4">EQUIPMENT CALIBRATOR SOURCING</h3>
            <p class="is-size-4 pl-6 ml-6">ION supplies high accuracy calibrator for testing and measurement instruments; pressure calibrator, temperature calibrator, electrical calibrator, mechanical calibration equipment, weight box, precision balance etc. to provide the best calibration service.</p>
          </div>

          <div class="pannel-box pb-6">
            <div class="circle is-size-1 has-text-weight-bold">03</div>
            <h3 class="title is-3 pl-6 ml-6 mb-4">URGENT EQUIPMENT SOURCING</h3>
            <p class="is-size-4 pl-6 ml-6">ION is the leading equipment service provider that imports all types of measuring equipment and is able to source it according to customer's demand.</p>
          </div>

          <div class="pannel-box pb-6">
            <div class="circle is-size-1 has-text-weight-bold">04</div>
            <h3 class="title is-3 pl-6 ml-6 mb-4">TRAINING & CONSULTATION</h3>
            <p class="is-size-4 pl-6 ml-6">ION provides equipment installation, operation, calibration training and laboratory consultation according to customers demand and satisfaction.</p>
          </div>

        </div>
        <div class="column is-6 comp-slider">

          <img-comparison-slider>
            <!-- eslint-disable -->
            <img
              slot="first"
              style="width: 100%;height: 420px;"
              src="@/assets/home/comp-img-1-min.webp"
            />
            <img
              slot="second"
              style="width: 100%;height: 420px;"
              src="@/assets/home/comp-img-2-min.webp"
            />
            <a href="#" class="button is-primary is-medium is-rounded py-2" slot="handle" style="margin-top: 120px;">
              <i class="fas fa-arrow-left"></i>
            </a>
            <!-- eslint-enable -->
          </img-comparison-slider>

          </div>
        </div>
    </div>
  </div>

  <div class="container my-6 regular-sourced-equipments">

    <div class="abbr">
      <h4 class="title is-2">REGULAR SOURCED EQUIPMENTS</h4>
    </div>

    <div class="columns my-3 has-text-centered">
      <div class="column is-2">
        <div class="box">
          <img height="185px" width="185px" alt="SOURCED PRODUCTS" src="@/assets/home/product-01-min.jpg">
        </div>
        <h5 class="title is-5 px-2">Micro Liquid Bath Calibrator</h5>
      </div>
      <div class="column is-2">
        <div class="box">
          <img height="185px" width="185px" alt="SOURCED PRODUCTS" src="@/assets/home/product-02-min.jpg">
        </div>
        <h5 class="title is-5 px-2">Very High Temperature Dry Block Calibrator</h5>
      </div>
      <div class="column is-2">
        <div class="box">
          <img height="185px" width="185px" alt="SOURCED PRODUCTS" src="@/assets/home/product-03-min.jpg">
        </div>
        <h5 class="title is-5 px-2">Precision Multifunction Process Calibrator</h5>
      </div>
      <div class="column is-2">
        <div class="box">
          <img height="185px" width="185px" alt="SOURCED PRODUCTS" src="@/assets/home/product-04-min.jpg">
        </div>
        <h5 class="title is-5 px-2">High Pressure Gauge Calibrator</h5>
      </div>
      <div class="column is-2">
        <div class="box">
          <img height="185px" width="185px" alt="SOURCED PRODUCTS" src="@/assets/home/product-05-min.jpg">
        </div>
        <h5 class="title is-5 px-2">Digital Micro Balance</h5>
      </div>
      <div class="column is-2">
        <div class="box">
          <img height="185px" width="185px" alt="SOURCED PRODUCTS" src="@/assets/home/product-06-min.jpg">
        </div>
        <h5 class="title is-5 px-2">Gauge Block Set</h5>
      </div>
    </div>

  </div>

</app-default>
</template>

<script>
export default {
  name: 'PageHome',
  metaInfo: {
    title: 'Best Calibration Service lab & Equipment Supplier Company In Bangladesh',
    meta: [
      { name: 'og:title', content: 'ION CALIBRATION SERVICE' },
      { name: 'keywords', content: 'Calibration, temperature calibration, pressure calibration,gas detector calibration,weight calibration, volume calibration,balance calibration,textile instruments calibration,fluke equipment calibration,calibrator,textile equipment calibration,pharmaceutical equipment calibration,equipment supplier, lab equipment supplier, lab machine supplier,iso 17025,iso 9001,' },
      
      { name: 'description', content: 'Bangladesh’s first software tracking base ISO/IEC 17025:2017 Accredited & ISO 9001:2015 Certified Calibration Laboratory.ION Engineering (Pvt.) Ltd is a Fully Equiped Calibration Laboratory As Well As Industrial Equipment, Laboratory Equipment & Measuring Devices Supplier.ION Engineering (Pvt.) Ltd Provides Accuracy Testing, Calibration, Training, Consultation And Measurement Solutions From Local And International Organizationals.' },

      { name: 'og:description', content: 'Bangladesh’s first software tracking base ISO/IEC 17025:2017 Accredited & ISO 9001:2015 Certified Calibration Laboratory.ION Engineering (Pvt.) Ltd is a Fully Equiped Calibration Laboratory As Well As Industrial Equipment, Laboratory Equipment & Measuring Devices Supplier.ION Engineering (Pvt.) Ltd Provides Accuracy Testing, Calibration, Training, Consultation And Measurement Solutions From Local And International Organizationals.' },
    ]
  }
}
</script>