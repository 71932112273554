<template>
<app-default class="about-us">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 230px;">
      <h1 class="title is-uppercase is-1 app-header-company-title">
        About Us
      </h1>
    </div>
    <div>
      <h3 class="subtitle is-5">
        <br/>
        <br/>
      </h3>
    </div>
  </template>

  <div class="my-6 container margin_mobile" style="max-width: 800px">
    
    <h3 class="subtitle is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">About ION</h3>
    <p>ION Engineering Pvt. Ltd was founded in 2020 as a NABL ISO-17025 accredited calibration laboratory. Our product suppling began and continues today with one singular focus; “Solutions in Calibration”. We supply products that allow our laboratory to function at the lowest uncertainties levels, calibrate equipment more efficiently, safer, and perhaps most importantly too many of our customers cost effectively as well as offering a wide ranging product lineup and an extensive range of calibration and other services. We have steadily grown by adding many new facilities, capabilities including primary and secondary instrumentation to become leading calibration laboratory in Bangladesh. We provide calibration services to our customers in either our own environmentally controlled multi-laboratory facility or on-site at their location.</p>

    <br/>

    <!-- <div class="columns">
      <img class="column is-4" src="@/assets/home-calibration-pic.jpg">
      <img class="column is-4" src="@/assets/home-calibration-pic.jpg">
      <img class="column is-4" src="@/assets/home-calibration-pic.jpg">
    </div> -->

    <br/>

    <h3 class="subtitle is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">Capabilities and Reference Standards</h3>
    <p>ION Calibration Laboratory has the most calibration capabilities under one roof to serve the varying needs of your business. We serve all industries, including aerospace, chemical, pharmaceuticals, engineering, automotive, biotech, high tech, manufacturing, medical and engineering from all around the world. We had the calibrated masters and reference standards for calibrating test equipment’s, process instruments</p>

    <br/> <br/>

    <async-img
      img_style="height:260px;width:190px;"
      v-if="$store.state.window_width > 1024"
      :src="require(`@/assets/about-us/about-us-min.webp`)" img_class="is-pulled-left mr-3" img_alt="iso-17015"/>


    <h3 class="subtitle is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">ION Engineering Care & Service</h3>
    <p>We believe in ‘Partnering Quality’ and we shall satisfy customer and applicable requirements by supplying world class. Metrology products and services adhering to highest standards through quality management system and continual improvement. To support this, we consider context of the organization and Strategic directions given by the top management.</p>

    <br/> <br/>

    <h3 class="subtitle is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">ION Engineering Policy</h3>
    <p>We believe in ‘Partnering Quality’ We shall satisfy customer and applicable requirements by supplying world class. Metrology Products and Services adhering to highest standards through Quality Management System and Continual improvement. To support this, we consider context of the organization and Strategic directions given by the Top Management.</p>

    <br/> <br/>

    <h3 class="subtitle is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">Lifecycle Support Policy</h3>
    <p>ION is dedicated to providing quality products to your business. We provide you with top-notch service and reliable products, with consistently high performance and precision. That is why we work hard and have earned the ISO designation. We are here to tailor our products to your needs, often partnering with our customers to create unique business solutions. We offer some of the most powerful, precise, and high-quality products and measuring units in the world. Our products are used by industry giants. We are proud to stand behind our products. Our team of professionals can discuss each product's unique warranty. Rest assured, you can count on ION's quality and reputation..</p>
      
  </div>

</app-default>
</template>

<script>
export default {
  name: 'AboutUs',
  metaInfo: {
    title: 'ION Calibration Laboratory',
    meta: [
      { name: 'og:title', content: 'Ion About us' },
      { name: 'keywords', content: 'Calibration, calibrate equipment,instruments calibration,Solutions in Calibration, calibration capabilities,pharmaceuticals calibration,medical calibration, Maintenance and Calibration,calibrating test equipment’s,process instruments' },
      
      { name: 'description', content: 'ION Calibration Laboratory has the most calibration capabilities under one roof to serve the varying needs of your business. We serve all industries, including aerospace, chemical, pharmaceuticals, engineering, automotive, biotech, high tech, manufacturing, medical and engineering from all around the world. We had the calibrated masters and reference standards for calibrating test equipment’s, process instruments' },

      { name: 'og:description', content: 'ION Calibration Laboratory has the most calibration capabilities under one roof to serve the varying needs of your business. We serve all industries, including aerospace, chemical, pharmaceuticals, engineering, automotive, biotech, high tech, manufacturing, medical and engineering from all around the world. We had the calibrated masters and reference standards for calibrating test equipment’s, process instruments' },
    ]
  }
}
</script>