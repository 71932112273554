import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    site_url: '',
    __is_loading_open: false,
    window_width: 0
  },
  mutations: {
    stateChange (state, payload) {
      state[payload.state] = payload.data
    }
  }
})
