<template>
<app-default class="verify-calibration">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 630px;">
      <h1 class="title is-uppercase is-1 app-header-company-title">
        Certificate Verification
      </h1>
      <h3 class="subtitle is-5"> &nbsp; <br/> <br/> </h3>
    </div>

  </template>

  <div class="my-6 container margin_mobile" style="max-width:900px;">
      <div class="mx-auto has-text-left" >
        <div class="columns is-multiline">


          <div class="column is-12 has-text-right">
            <h1 class="title is-5">
              Calibration | Validation | Inspection
              <span class="is-size-5 py-2 has-text-grey is-pulled-left">Enter Certificate Number To Get Certificate's</span>
            </h1>
          </div>

          <div class="column is-12  has-text-centered">
            <div class="box">
              <div class=" px-1 py-4"  style="display:inline-block;" v-if="!is_varified">

                <div class="field has-addons mx-auto">
                  <div class="control has-icons-left">
                    <input type="text" v-model="search" placeholder="Type certificate number here ..." class="input is-primary is-medium">
                    <span class="icon is-small is-left">
                      <i class="fas fa-search"></i>
                    </span>
                  </div>
                  <div class="control">
                    <button class="button is-primary is-medium" @click.prevent="verifyCertificate()">
                      Verify
                    </button>
                  </div>
                </div>               
                
              </div>

              <div class=" px-1 py-4"  style="display:inline-block;" v-if="is_varified">
                <div v-if="result">
                  <h3 class="title is-1 has-text-success">Success, Certificate record found</h3>
                  Click here to View or download the certificate of calibration <br/> <br/>
                  <a :href="'https://files.ionbd.com/download?certificate_no='+search" class="button is-primary">Download PDF</a> &nbsp;
                  <button class="button is-primary is-outlined" @click.prevent="is_varified=false,result=false">Go Back</button>
                </div>
                <div v-if="!result">
                  <h3 class="title is-1 has-text-danger">This Certificate Number Invalid</h3>
                  No Record Found. please email the certificate and number to contact@ionbd.com. <br/> <br/>
                  <button class="button is-primary is-outlined" @click.prevent="is_varified=false,result=false">Try Again</button>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container has-text-centered">
          <h3 class="title">Certificate Verification  </h3>
          <p class="my-3 head">
           ION Engineering (Pvt.) Ltd introduced the service for verifying the certificate through the link and QR from 1st May 2022. ION Engineering (Pvt.) Ltd placed a link and a QR in the calibration certificate and sticker with a unique serial number. Who has this link in his/her certificate only he/she can verifiy. If you do not find the required information regarding verification then please contact us through 
          </p>
          <span class="">
            <a href="tel:+8801979994211" class="button is-outlined is-primary is-large mr-1">+88 02 44806139</a>
            <a href="mailto:support@cibs.net.bd" class="button is-primary is-large ml-1">contact@ionbd.com</a>
          </span>
        </div>

      </div>

  </div>

  

  

  

</app-default>
</template>

<script>
export default {
  name: 'VerifyCalibration',
  metaInfo: {
    title: 'Certificate Verification',
    meta: [
      { name: 'og:title', content: 'Contact' },
      { name: 'keywords', content: 'ion Contact, calibration Contact, calibrator supplier,calibration certificate, inspection certificate, validation certificate, environmental inspection certificate, 17025 certificate, calibration certificate verification, verification ' },

      { name: 'description', content: 'ION Engineering (Pvt.) Ltd introduced the service for verifying the certificate through the link and QR from 1st May 2022. ION Engineering (Pvt.) Ltd placed a link and a QR in the calibration certificate and sticker with a unique serial number. Who has this link in his/her certificate only he/she can verifiy. If you do not find the required information regarding verification then please contact us through' },

      { name: 'og:description', content: 'ION Engineering (Pvt.) Ltd introduced the service for verifying the certificate through the link and QR from 1st May 2022. ION Engineering (Pvt.) Ltd placed a link and a QR in the calibration certificate and sticker with a unique serial number. Who has this link in his/her certificate only he/she can verifiy. If you do not find the required information regarding verification then please contact us through ' },
    ]
  },
  data: function () {
    return {
      search: null,
      is_varified: false,
      result: false
    }
  },
  methods: {
    verifyCertificate() {
      let vm = this;
      vm.$http.get('https://files.ionbd.com/download?certificate_no='+vm.search).then(() => {
        vm.result = true
      }).catch(() => {
        vm.result = false
      }).then(() => {
        vm.is_varified = true
      })
    }
  },
  mounted() {
    if(this.$route.query.id) {
      this.search = this.$route.query.id
      this.verifyCertificate()
    }
  }
}
</script>