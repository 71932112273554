<template>
<app-default class="lab-consultation">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 440px;">
      <h1 class="title is-uppercase is-1 app-header-company-title">
        Lab Consultation
      </h1>
    </div>
    <div>
      <h3 class="subtitle is-5">
        STARTING FROM <b class="is-size-3">Lab Setup, Consultancy, Instrument Sourcing </b> TO <b class="is-size-4">Accreditation</b>
        <b class="is-size-4"></b> <b class="is-size-4"></b>
      </h3>
    </div>
  </template>

  <div class="my-6 container margin_mobile" style="max-width: 800px">

    <div class="columns has-text-centered">
      <div class="column is-4">
        <async-img
          img_style="height:180px;width:200px;"
          :src="require(`@/assets/lab-consultation/iso-17025-1-min.webp`)" img_alt="iso-17015"/>
      </div>
      <div class="column is-4">
        <async-img
          v-if="$store.state.window_width > 1024"
          img_style="height:180px;width:200px;"
          :src="require(`@/assets/lab-consultation/iso-17025-2-min.webp`)" img_alt="CALIBRATION Quality"/>
      </div>
      <div class="column is-4">
        <async-img
          img_style="height:180px;width:200px;"
          :src="require(`@/assets/lab-consultation/iso-17025-3-min.webp`)" img_alt="iso-17025"/>
      </div>
    </div>
    
    <h2 class="title is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">Consultancy Services</h2>
    <p>ION provides quality lab services in the field of calibration engineering. Laboratories are what we do! Let us use our expertise to assist you in creating a highly functional and profitable laboratory for your office. We know what you need and how to avoid issues because of our 07+ years of experience building labs. ION can manage your entire laboratory project for you. We provide layout, workflow, assistance with product selections and Products.ION Lab Services has a great team of laboratory consultants and other professionals to help you manage your lab effectively. Whether you are starting a new lab or looking to grow your current business, we offer laboratories a way to accelerate their success.</p>

    <br/> <br/>

    <h2 class="title is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">Quality Management System</h2>
    <p>The key element to you getting ISO 17025 accredited is your quality management system. It all begins with your quality manual and standard operating procedures. We will create an easy to read and use quality manual that meets the ISO 17025 standard and reflects the processes you are already performing. We will take on 100% of the workload and provide you with a unique quality manual, standard operating procedures, and forms so you can become ISO 17025 accredited by your accreditation body of choice without any of the stress and headaches.</p>

    <br/> <br/>

    <async-img
      img_style="height:100px;width:150px;"
      :src="require(`@/assets/lab-consultation/iso-17025-4-min.webp`)" img_class="is-pulled-left mr-3" img_alt="iso-17005"/>

    <h2 class="title is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">Lab Equipment Source</h2>
    <p>Lab Equipment Source has a staff of highly trained and qualified technicians with over 07 years of combined service experience. We pride ourselves on being available and responsive to our customer's service needs be it on site, depot service or technical phone support to handle your laboratory equipment needs.Our work speaks for itself.  Working with ION will help you save money in the long run because we know what your lab needs to be successful.</p>

    <br/> <br/>

    <h2 class="title is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">Measurement Uncertainty Analysis</h2>
    <p>Measurement uncertainty analysis is one of the most difficult tasks laboratories encounter when getting ISO 17025 accredited. It’s probably why it is one of the top 10 deficiencies! With our expertise, software, and tools, we can make measurement uncertainty analysis stress free.With all of this information, we will perform measurement uncertainty analysis and provide you with custom reports that will give you uncertainty budgets and CMC uncertainty statements for you ISO 17025 Scope of Accreditation. To make things easier, your reports will even so you how to add your CMC uncertainty statements to your scope of accreditation.</p>

    <br/> <br/>

    <h2 class="title is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">Measurement Traceability</h2>
    <p>The most common deficiency in ISO 17025 accreditation is measurement traceability. You would not believe how many laboratories get cited for this deficiency. It’s mind-blowing! As your consultant, we will review each and every one of your calibration reports to verify you have measurement traceability. When we identify areas where you do not have measurement traceability, we will help you find a laboratory that can provide you traceable calibrations so you can become ISO 17025 accredited.</p>


    <br/> <br/>

    <h2 class="title is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">Proficiency Testing or ILC</h2>
    <p>An essential part of proper proficiency testing is using and maintaining a documented plan for two years of sampling activities. This document needs to include a yearly proficiency test (PT) or inter-laboratory comparison (ILC) covering the appropriate parameters, ranges, measurements, test technologies, inspections, methods, and uncertainty of measurements. You may use a PT/ILC provider if they are accredited to ISO/IEC 17043. However, if you choose to develop your own ILC, you need further approval from BAB/NABL/ANAB etc. Furthermore, a successful PT/ILC must be completed within 12 months of applying for ISO 17025 accreditation.<br/> <br/>
    ION Engineering has more than 07+ years of experience in calibration programs, metrology, criticality analysis, risk assessment, process validation and can provide you with calibrated, validated equipment for your metrology needs. Contact us for more information on how our metrology services can help you in ISO 17025 accreditation.
    </p>
      
  </div>

</app-default>
</template>

<script>
export default {
  name: 'LabConsultation',
  metaInfo: {
    title: 'Best ISO lab Consultancy Services',
    meta: [
      { name: 'og:title', content: 'Lab Consultancy Services' },
      { name: 'keywords', content: 'laboratory Consultancy Services, lab Consultancy Services, iso 17025 Consultancy, iso 9001 Consultancy, calibration lab Consultancy Service, quality,uncertainty traning, uncertainty analysis,scope of accreditation,calibration program, metrology, traceable calibration, laboratory consultants' },
      
      { name: 'description', content: 'Laboratories are what we do! Let us use our expertise to assist you in creating a highly functional and profitable laboratory for your office. We know what you need and how to avoid issues because of our 07+ years of experience building labs.ION Lab Services has a great team of laboratory consultants and other professionals to help you manage your lab effectively.' },

      { name: 'og:description', content: 'Laboratories are what we do! Let us use our expertise to assist you in creating a highly functional and profitable laboratory for your office. We know what you need and how to avoid issues because of our 07+ years of experience building labs.ION Lab Services has a great team of laboratory consultants and other professionals to help you manage your lab effectively.' },
    ]
  }
}
</script>