<template>
<footer class="app-footer has-text-white">

  <div class="app-footer-top">

    <br/>
    <br/>

    <p class="is-size-3 container has-text-right">
     <span class="is-pulled-left">Calibration | Validation | Inspection</span>
      Verify your certificate   &nbsp;      
      <router-link :to="{ name: 'VerifyCalibration'}" class="button is-large is-dark px-6 py-4 mt-2"  style="display: inline;position: relative;top:3px;">
              Certificate Verification
            </router-link>
          </p>

    <br/>
    <br/>

  </div>

  <br/>

  <div class="container py-6">
    <div class="columns">
      <div class="column is-5">
        <span class="icon is-large has-text-grey">
          <i class="fa fa-fas fa-headset fa-3x"></i>
        </span> <br/>
        <span class="is-size-5 has-text-grey"> Any Question? Call us !</span><br/>
        <span class="is-size-1">+95 9 44376 2421</span><br/>
        <span class="is-size-1">+88 01713 588601 (Whatsapp)</span><br/>
      </div>
      <div class="column is-3 has-text-right">
        <h5 class="title is-4 has-text-grey">Our Services</h5>
        <router-link class="is-size-4" :to="{ name: 'CalibrationService'}">Calibration & Validation</router-link> <br/>
        <router-link class="is-size-4" :to="{ name: 'CalibrationCapabilities'}">Mechanical Test & TAG Testing & Inspection</router-link> <br/>
        <router-link class="is-size-4" :to="{ name: 'LabConsultation'}">LAB Consultation Services</router-link> <br/>
        <router-link class="is-size-4" :to="{ name: 'CalibrationCapabilities'}">Ion Engineering Offers</router-link>
      </div>
      <div class="column is-2 has-text-right">
        <h5 class="title is-4 has-text-grey">Our Company</h5>
        <router-link class="is-size-4" :to="{ name: 'WhyIon'}">Why ION Engineering?</router-link> <br/>
        <router-link class="is-size-4" :to="{ name: 'CalibrationService'}">Calibration Service</router-link> <br/>
        <router-link class="is-size-4" :to="{ name: 'VerifyCalibration'}">Certificate Verification</router-link> <br/>
        <router-link class="is-size-4" :to="{ name: 'Contact'}">Contact Us</router-link>
      </div>
      <div class="column is-2 has-text-right">
        <h5 class="title is-4 has-text-grey">About ION</h5>
        <router-link class="is-size-4" :to="{ name: 'WhyIon'}">Who are we</router-link> <br/>
        <router-link class="is-size-4" :to="{ name: 'AboutUs'}">About Us</router-link> <br/>
        <router-link class="is-size-4" :to="{ name: 'MissionVission'}">Mission Vission</router-link> <br/>
        <router-link class="is-size-4" :to="{ name: 'IonCareer'}">Career in Ion Engineering</router-link>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <span class="is-size-3 has-text-grey">CONTACT INFO</span><br/>
        <span class="is-size-4">No.96/B, Thiri Mingalar (8) Street, East Yward, Insein Tsp <br/> Yangon-11012, Myanmar (Burma)</span>
      </div>
      <div class="column is-6 has-text-right"> <br/><br/>  @copyright 2022 IONCL all right reserved.</div>
    </div>
  </div>

  <br/>

</footer>
</template>
<script>
export default {
  name: 'AppFooter'
}
</script>