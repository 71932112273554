<template>
<div id="app">

  <router-view></router-view>

</div>
</template>

<script>

export default {
  name: 'App',
  metaInfo: {
    titleTemplate: '%s | ION ENGINEERING',
    meta: [
      { charset: 'utf-8' },
      { name: 'robots', content: 'index, follow' },
      { "http-equiv":"X-UA-Compatible", content:"IE=edge" },

      { name: 'og:type', content: 'website' },
      { name: 'og:image', content: 'LINK TO THE IMAGE FILE' },
      { name: 'og:locale', content: 'en_BD' },
      // { name: 'og:url', content: window.location },
      { name: 'og:site_name', content: 'ION ENGINEERING' },

      // Facebook, Twitter

    ]
  },
  methods: {
    addEvent(object, type, callback) {
      if (object == null || typeof(object) == 'undefined') return;
      if (object.addEventListener) {
          object.addEventListener(type, callback, false);
      } else if (object.attachEvent) {
          object.attachEvent("on" + type, callback);
      } else {
          object["on"+type] = callback;
      }
    }
  },
  mounted() {
    
    let vm = this
    vm.$store.commit('stateChange', { state:'window_width', data: window.innerWidth})
    vm.addEvent(window, "resize", function(event) {
      vm.$store.commit('stateChange', { state:'window_width', data: event.target.innerWidth})
    });

    // Your CSS as text
    var styles = `.first_loader { display: none; }`
    var styleSheet = document.createElement("style")
    // styleSheet.innerText = styles

    if(this.$store.state.window_width > 1024) {
      // Your CSS as text
      styles += `.app-header-wraper { background: url('`+ require(`@/assets/default/home-background.webp`) +`'); }`
      // var styleSheet = document.createElement("style")
      // document.head.appendChild(styleSheet)
    }

    styleSheet.innerText += styles
    document.head.appendChild(styleSheet)

  }
}
</script>
