<template>
<app-default class="mission-vission">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 510px;">
      <h1 class="title is-uppercase is-1 app-header-company-title">
        Mission & Vission
      </h1>
    </div>
    <div>
      <h3 class="subtitle is-5">
        STARTING FROM <b class="is-size-3">CALIBRATION,TRAINING,CONSULTATION</b> TO <b class="is-size-4">INSTRUMENT SOURCING</b>
        <b class="is-size-4"></b>  <b class="is-size-4"></b>
      </h3>
    </div>
  </template>

  <div class="my-6 container margin_mobile" style="max-width: 800px">
    
    <h3 class="subtitle is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">MISSION</h3>
    <p>We serve, standardize and calibrate your equipment with a special care. Our Mission is to give high quality and accurate calibration with national or international recognition for wide range of measurement scope with unbroken chain of traceability to national or international standards.
    </p>

    <br/>

    <!-- <div class="columns">
      <img class="column is-4" src="@/assets/home-calibration-pic.jpg">
      <img class="column is-4" src="@/assets/home-calibration-pic.jpg">
      <img class="column is-4" src="@/assets/home-calibration-pic.jpg">
    </div> -->

    <br/>

    <h3 class="subtitle is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">VISION</h3>
    <p>Our vision is to be the prime service providers and the number one supplier of choice in the field of calibration, testing and consulting services leading to a global recognition.</p>

    <br/> <br/>
<!-- 
    <img class="is-pulled-left mr-3" src="@/assets/home-calibration-pic.jpg" style="max-width: 25%">

    <h3 class="subtitle is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">What is Lorem Ipsum?</h3>
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum versions of Lorem Ipsum.</p>

    <br/> <br/>

    <h3 class="subtitle is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">What is Lorem Ipsum?</h3>
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

    <br/> <br/>

    <h3 class="subtitle is-2 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">What is Lorem Ipsum?</h3>
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> -->
      
  </div>

</app-default>
</template>

<script>
export default {
  name: 'MissionVission',
  metaInfo: {
    title: 'ION Calibration Service Mission & Vission',
    meta: [
      { name: 'og:title', content: 'Mission Vission' },
      { name: 'keywords', content: 'ion calibration, ion mission, ion vision, ion quality, ion traceability,ion calibration' },
      
      { name: 'description', content: 'We Serve, We Standardize, We Calibrate your equipment with a special care…. Our Mission is to give high quality and accurate Calibration with National or International Recognition for wide Range of measurement scope with unbroken chain of traceability to National or International Standards.' },

      { name: 'og:description', content: 'We Serve, We Standardize, We Calibrate your equipment with a special care…. Our Mission is to give high quality and accurate Calibration with National or International Recognition for wide Range of measurement scope with unbroken chain of traceability to National or International Standards.' },
    ]
  }
}
</script>