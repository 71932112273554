<template>
<header class="app-header">

  <app-navbar></app-navbar>

  <div class="app-header-wraper" style="overflow:hidden;">

    <img v-if="$store.state.window_width > 1024" class="app-header-decorator dec-01" src="@/assets/default/meter-min.png" alt="hamer">
    <img v-if="$store.state.window_width > 1024" class="app-header-decorator dec-02" src="@/assets/default/ranch-min.png" alt="ranch">
    <img v-if="$store.state.window_width > 1024" class="app-header-decorator dec-03" src="@/assets/default/tape-min.png" alt="tape">

    <section class="hero is-small has-text-centered container">
      <div class="hero-body">
        <div class="hero-body-wraper">
          <slot></slot>
        </div>
      </div>
    </section>

  </div>

</header>
</template>

<script>
import AppNavbar from '@/parts/AppNavbar.vue'

export default {
  name: 'AppHeader',
  components: {
    AppNavbar
  },
  data: function () {
    return {
      window_width: 0,
      background_img: null
    }
  }
}
</script>