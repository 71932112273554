<template>
<app-default class="equipment-detail">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 260px;">
      <h1 class="title is-1 app-header-company-title">
        EQUIPMENT DETAILS
      </h1>
    </div>
    <div class="">
      <h3 class="subtitle is-5">
        <span class="is-size-2"> {{ data.name }} </span>
      </h3>
    </div>
  </template>

  <div class="my-6 container margin_mobile">
    
      <div class="columns">
        <div class="column is-4 has-text-centered">
          <h2 class="subtitle is-3 has-text-">
            <button class="button is-primary is-outlined is-uppercase">
              <span v-if="$store.state.window_width > 1024">{{ data.name }}</span>
              SOURCE REQUEST
            </button>
          </h2>
          <async-img
            img_style="height:300px;width:300px;"
            :src="$store.state.site_url + '/products/'+ $route.params.slug +'/thumbnail.300.webp'" :img_alt="data.name"/>
        </div>
        <div class="column is-8">
          <h2 class="title is-2 box has-background-primary has-text-white py-2 mb-1 mt-6">{{ data.name }}</h2>
          <p class="is-size-4 py-2 mb-1">{{ data.description }}</p>
          <h2 class="title is-3 box has-background-primary has-text-white py-2 mb-1 mt-5"> Equipment Features </h2>
          <p class="is-size-4 py-2 mb-1 mx-6">
            <ul type="I">
              <li class="my-2 " v-for="(feature, key) in data.features" :key="key">
                <span class="icon has-text-primary">
                  <i class="fas fa-info-circle"></i>
                </span> &nbsp;
                {{ feature }}
              </li>
            </ul>
          </p>
        </div>
      </div>
      <div>
        <h2 class="title is-2 box has-background-primary has-text-white py-2 mb-1"> Equipment Specifications </h2>
        <table class="table is-bordered" width="100%">
          <tbody v-for="(spec, key) in data.specifications" :key="key">
            <tr v-for="(spec_detail, spec_key) in spec" :key="spec_key">
              <th> {{ spec_key }} </th>
              <td> {{ spec_detail }} </td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>

</app-default>
</template>

<script>
export default {
  name: 'EquipmentDetail',
  metaInfo() {
    return {
      title: this.data.name,
      meta: [
        { name: 'keywords', content: this.data.keywords }, 
        { name: 'description', content: this.data.description }
      ]
    }
  },
  data: function () {
    return {
      data: {}
    }
  },
  methods: {
    getProductData() {
      let vm = this;
      vm.$http.get(vm.$store.state.site_url + '/products/' + vm.$route.params.slug + '/data.json').then((response) => {
        vm.data = response.data.payload
      }).catch(() => {})
    }
  },
  mounted() {
    this.getProductData()
  }
}
</script>