<template>
<app-default class="ion_career">
  
  <template #header>
    <div class="typewriter mt-6 pt-6" style="width: 660px;">
      <h1 class="title is-uppercase is-1 app-header-company-title">
        Career in ION ENGINEERING
      </h1>
    </div>
    <div>
      <h3 class="subtitle is-5">
        STARTING FROM <b class="is-size-3">CALIBRATION, CONSULTATION</b> TO <b class="is-size-4">INSTRUMENT SOURCING</b>
        <b class="is-size-4">YOUR URGENCY</b> IS <b class="is-size-4">OUR PRIORITY</b>
      </h3>
    </div>
  </template>

  <div class="my-6 container margin_mobile" style="max-width: 800px">
    
    <h2 class="title is-3 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">Career with us !</h2>
    <p>"SUCCESS usually comes to those who are too busy to be looking for it."  ION Engineering Pvt. Ltd. is an equal opportunity employer which provides excellent working environment with competitive salaries, commensurate with experience and qualification.We are looking for talented and experienced professionals who never want to stop learning. As a multi-disciplinary laboratory, we give our staff an unparalleled and enriching experience to work and learn in various testing fields like Chemical, Mechanical, NDT, Calibration, Electrical and Biological. We encourage our people to constantly improve their knowledge and skills. In relation to that we provide relevant 'Training' to our staff on a regular basis so as to keep them abreast with the latest technological developments.</p>

    <br/>

    <!-- <div class="columns">
      <img class="column is-4" src="@/assets/home-calibration-pic.jpg">
      <img class="column is-4" src="@/assets/home-calibration-pic.jpg">
      <img class="column is-4" src="@/assets/home-calibration-pic.jpg">
    </div>
 -->
    <br/>

    <h2 class="title is-3 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">Good reasons to make ION a part of your life!</h2>
    <p>A high technological demand for world-class products, a variety brought about by customers from the widest range of industries and a family like cooperation there's a lot going for ION as an employer. You can turn your brainstorms into successful projects with us. You can be in charge of your projects from the idea to the end result with ION. Constant change and new challenges are thus guaranteed. Whether directly on site or from your home country. Through strong international growth, opportunities and possibilities are opening for you worldwide to shape things.</p>

    <br/> <br/>

    <h2 class="title is-3 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">We encourage your talents and open up prospects!</h2>
    <p>We work together with our customers on specific solutions and to develop custom, high-value products. In your personal development also, individuality is paramount. Together with your manager and personnel development, actions are planned and implemented. In this way, you will receive a customized training programme that is tailored to your area of responsibility. If you are considering a management or specialist career, we will prepare you in the best possible way for this function. Within the team leader programme or in the ION talent programme, you will receive, over the course of several seminar modules, the required skills for your management or professional function.</p>

    <br/> <br/>

    <h2 class="title is-3 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-right">We are looking for personalities!</h2>
    <p>Are you in search of a new task and a challenge? Do you want to make the next career move? Then ION is the right employer for you! As a company growing worldwide, with a very wide product portfolio, ION always has new challenges and fields of responsibility to offer. Internationally and nationally, you have the possibility to take responsibility and to show what you're made of! We will support your talent and competence and give you the space to use these to the best. Whether in development, product management, IT or sales - in all ION business divisions a structured initial training is guaranteed. You will therefore receive a professional induction and can also build your personal network. Despite the international structure and the size of the company, a family-like cooperation prevails within ION. Helpful colleagues provide for a speedy orientation and will support you in your initial time at ION. Responsible challenges and a good initial training alone, of course, do not make an attractive working environment!</p>

    <br/> <br/>

    <h2 class="title is-3 box has-background-primary has-text-white py-1 mb-1 mx-3 is-pulled-left">Enjoy your work and life!</h2>
    <p>ION offers a self-regulated and joyful work and long term balanced employment condition, promoting work and life harmony with periodic outdoor events, such as sports activities and workshops. Company’s aim is to maintain a high level of work satisfaction thereby leading to the long-term employment.</p>
      
  </div>

</app-default>
</template>

<script>
export default {
  name: 'IonCareer',
  metaInfo: {
    title: 'Ion Calibration Labs Career',
    meta: [
      { name: 'og:title', content: 'Ion Career' },
      { name: 'keywords', content: 'Ion cateer, ion calibration engineer,ION Engineering, Chemical, Mechanical, NDT test, Calibration, Electrical, Biological,' },
      
      { name: 'description', content: 'A high technological demand for world-class products, a variety brought about by customers from the widest range of industries and a family like cooperation theres a lot going for ION as an employer. You can turn your brainstorms into successful projects with us. You can be in charge of your projects from the idea to the end result with ION. Constant change and new challenges are thus guaranteed.' },

      { name: 'og:description', content: 'A high technological demand for world-class products, a variety brought about by customers from the widest range of industries and a family like cooperation theres a lot going for ION as an employer. You can turn your brainstorms into successful projects with us. You can be in charge of your projects from the idea to the end result with ION. Constant change and new challenges are thus guaranteed.' },
    ]
  }
}
</script>