<template>
  <img v-if="url" :src="url" :class="img_class" :style="img_style" :alt="img_alt" rel="preload">
</template>

<script>
export default {
  name: 'AsyncImg',
  props: {
    src: String,
    img_alt: String,
    img_style: String,
    img_class: String
  },
  data: function () {
    return {
      url: null
    }
  },
  mounted() {
    let vm = this
    if(vm.src)
      // setTimeout(() => {
        vm.url = vm.src
      // }, 500)
  }
}
</script>
